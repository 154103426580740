import React, { useCallback, useState } from "react"

import Container from "@ecom/ui/components/Container"

import { StaticImage } from "gatsby-plugin-image"

import { FormikForm } from "../FormikForm"

import disableWebvisor from "../../utils/disableWebvisor"

import * as styles from "./newForm.module.scss"
import { handleClickForm } from "../../helpers/WatcherDL/utils/handleClickForm"

export type NewFormProps = {
  title?: React.ReactNode
  dataLayerName?: string
  formBtnText?: string
  darkColor?: boolean
  orderNum?: string
}

export function NewForm({
  title = "Оформите карту",
  dataLayerName,
  formBtnText,
  darkColor,
  orderNum,
}: NewFormProps) {
  const [clickOnInput, setClickOnInput] = useState(false)

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  const handleClick = (e: any) => {
    if (!clickOnInput && e.target.nodeName === "INPUT") {
      handleClickForm()
      setClickOnInput(true)
    }
  }

  return (
    <section className={styles.section} data-exclude={orderNum}>
      <div className={styles.anchor} id="app" />
      <Container className={darkColor && styles.darkColor}>
        <div className={styles.form}>
          {title && (
            <div className={styles.info}>
              <h3 className={styles.head} ref={ref}>
                {title}
              </h3>
              <p className={styles.descr}>
                Быстрая&nbsp;и&nbsp;бесплатная доставка&nbsp;или&nbsp;самовывоз
              </p>
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.formContainer}
            onClick={(e) => handleClick(e)}
            onKeyDown={(e) => handleClick(e)}
          >
            <FormikForm dataLayerName={dataLayerName} formBtnText={formBtnText} />
          </div>
          <div>
            <StaticImage
              className={styles.img}
              alt="women"
              src="./images/women.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}
